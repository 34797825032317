import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5d0006bb"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeCard: require('/app/components/HomeCard/index.vue').default,SectionTitle: require('/app/components/SectionTitle/SectionTitle.vue').default,HomeCardProgram: require('/app/components/HomeCard/Program.vue').default,HomeCardPodcast: require('/app/components/HomeCard/Podcast.vue').default})
