
import { debounce } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { PROGRAM_ACTIONS, PODCAST_ACTIONS, TITLES } from '~/constants'
import { sort } from '~/utils'
import { metaDataMixin } from '~/mixins'

const PODCAST_CHANNEL_ACTION = 'podcast-channels/proxyAction'
const PROGRAM_CHANNEL_ACTION = 'program-channels/proxyAction'
const PODCAST_CHANNEL_STORE = 'podcast-channels'
const PROGRAM_CHANNEL_STORE = 'program-channels'

export default {
  mixins: [
    metaDataMixin,
  ],
  async middleware ({ store }) {
    await Promise.all([
      store.dispatch(PODCAST_CHANNEL_ACTION, { action: PODCAST_ACTIONS.ACTION_PODCAST_FEATURED, offset: 0, limit: 2 }),
      store.dispatch(PODCAST_CHANNEL_ACTION, { action: PODCAST_ACTIONS.ACTION_PODCAST_POPULAR, offset: 0, limit: 8 }),
    ])
  },
  asyncData ({store}) {
    store.dispatch('breadcrumbs/set', {
        path: '/podcasts' ,
        eoRoot: true,
        items: [
        {
          label: 'Podcasts',
        },
      ],
    })
  },
  data () {
    return {
      podcastsAll: [],
      podcasts: [],
      isSearching: false,
      searchInput: '',
      podcastActions: PODCAST_ACTIONS,
      seo: {
        title: TITLES.TITLE_PODCASTS,
      },
      searchTerms: {
        podcast: '/zoeken?invoer=*&category=Luisteren',
        radio: '/zoeken?invoer=radio&category=Luisteren',
      },
    }
  },
  computed: {
    featured () {
      return this.$store.getters[`${PODCAST_CHANNEL_STORE}/podcast_featured`]
    },
    popular () {
      return this.$store.getters[`${PODCAST_CHANNEL_STORE}/podcast_popular`]
    },
    radio () {
      return this.$store.getters[`${PROGRAM_CHANNEL_STORE}/program_radio`]
    },
    ...mapGetters('loading', ['isLoading']),
  },
  watch: {
    searchInput () {
      this.search()
    },
  },
  async mounted () {
    this.$store.dispatch(PODCAST_CHANNEL_ACTION, { action: PODCAST_ACTIONS.ACTION_PODCAST_FEATURED, offset: 0, limit: 2 })
    this.$store.dispatch(PODCAST_CHANNEL_ACTION, { action: PODCAST_ACTIONS.ACTION_PODCAST_POPULAR, offset: 0, limit: 8 })
    this.$store.dispatch(PROGRAM_CHANNEL_ACTION, { action: PROGRAM_ACTIONS.ACTION_PROGRAM_RADIO, offset: 0, limit: 8 })
    this.podcastsAll = await this.getPodcasts()
  },
  methods: {
    ...mapActions('general-channels', [
      'proxyAction',
    ]),
    isPodcast (item) {
      return item.podcast && Object.keys(item.podcast).length > 0
    },
    search: debounce(function () {
      this.isSearching = true
      if (this.searchInput.length === 0) {
        this.isSearching = false
        return
      }
      this.podcasts = this.podcastsAll.filter(p => p.title.toLowerCase().includes(this.searchInput.toLowerCase()))
      this.isSearching = false
    }, 500),
    async getPodcasts () {
      const { data } = await this.$api.podcasts.getAll({
        limit: 100,
        offset: 0,
        textsearch: this.searchInput,
        domain: this.$config.preprEnvironmentDomainGeneriek,
      })
      return sort.alphabetically(data?.items, 'title')
    },
    reset () {
      this.searchInput = ''
    },
  },
}
