
export default {
  props: {
    publication: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      styles: {
        minWidth: null,
        width: null,
      },
    }
  },
  computed: {
    cardProps () {
      return {
        to: this.to,
        image: this.image,
        title: this.title,
        titleLines: this.titleLines,
        titleSize: this.titleSize,
        imageSquare: true,
        imageSize: this.imageSize,
        minHeight: this.minHeight,
        textCentered: !this.isSmall,
        style: this.styles,
      }
    },
    image () {
      return this.publication?.logo || this.publication?.imageUrl || this.publication?.hero || this.publication?.heroUrl
    },
    imageSize () {
      return this.$attrs['image-size'] || 'xs:340x340 sm:369x369 md:372x372 lg:407x407'
    },
    isImageFull () {
      return this.$attrs['image-full'] || this.$attrs['image-full'] === ''
    },
    isSmall () {
      return this.$attrs.small || this.$attrs.small === '' || this.$attrs.tiny || this.$attrs.tiny === ''
    },
    minHeight () {
      return this.$attrs['min-height'] || (this.isSmall ? this.isImageFull ? '100%' : '100px' : null)
    },
    title () {
      return this.isSmall && this.isImageFull ? null : this.publication?.title
    },
    titleLines () {
      return this.isSmall ? 2 : 3
    },
    titleSize () {
      return this.isSmall ? 'sm' : 'md'
    },
    to () {
      return this.$link.publication(this.publication)
    },
  },
}
